import { gql, useQuery } from '@apollo/client';

import { Department } from 'model/misc';

export const DEPARTMENTS_GQL = gql`
  query Departments($filter: DepartmentsFilter) {
    departments(filter: $filter) {
      edges {
        id
        name
        school {
          id
          name
        }
        center
      }
    }
  }
`;

export interface DepartmentFilter {
  schoolId?: string;
}

export const useDepartments = (filter?: DepartmentFilter) => {
  const { data, ...rest } = useQuery<{ departments: { edges: Department[] } }>(DEPARTMENTS_GQL, {
    variables: {
      filter,
    },
  });

  return {
    departments: data?.departments?.edges,
    ...rest,
  };
};

// TODO: not sure, why useDepartments and useTeacherCenters are both required. maybe having just 1 would be enough?
export const useTeacherCenters = (filter?: DepartmentFilter) => {
  const { departments } = useDepartments(filter);

  return departments?.map((department) => ({ value: department.id, name: department.name })) || [];
};

// TODO: this should use a dedicated GQL query, that fetches a department by ID, instead of all departments.
export const useTeacherCenterById = (id: string | undefined) => {
  const { departments } = useDepartments();

  return departments?.find((department) => department.id === id);
};
