import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import CloseIcon from 'assets/close-drawer.svg';
import { PrimaryButton, SecondaryButton } from 'components/Button';
import Icon, { IconUnion } from 'components/Icon';
import LoadingMessage from 'components/Loading';
import MultiselectDropdown from 'components/MultiselectDropdown';
import { toaster, ToasterType } from 'components/Toaster';
import {
  allPersonalInterests,
  allPersonalInterestsLabels,
  PersonalInterest,
  personalInterestsIcons,
  allSkillsLabels,
  Skill,
} from 'config/teacherProfiles';
import { ITeacher } from 'model/profiles';
import { useTeacherCenterById } from 'network/graphql/Departments';
import { fetchTeacherProfile, TeacherProfileToUpdate, updateTeacherProfile, useLanguageOptions } from 'network/Profile';
import { useTeacherPermissions } from 'utils/hooks/usePermissions';
import { getEnglishFullLangName, diffChangedProps, ddlog } from 'utils/miscellaneous';

import { RenderBasics, renderLabeledValue, renderList, ViewContent } from './helpers';
import {
  ButtonWrapper,
  CloseWrapper,
  ExpandedContainer,
  FieldContainer,
  Hr,
  Label,
  SubNavButton,
  SubNavigation,
  SubNavText,
  TeacherEditWrapper,
  TeacherViewWrapper,
  Tile,
  TileContainer,
  School,
  TileText,
} from './styledComponents';

interface Props {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  teacherId: string;
  school?: string;
  profile?: ITeacher;
  isLarge?: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedTeacher?: Dispatch<SetStateAction<ITeacher>>;
  onSave?(teacher: ITeacher): void;
}

export interface InnerProps {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  isOpen: boolean;
  profile: ITeacher;
  triggerTeacherCb?: (evt?: MouseEvent) => void;
  onUpdateProfile(newProfile: TeacherProfileToUpdate): void;
}

const useField = <T extends unknown>(initialValue: T, validator?: (arg?: T) => boolean, defaultValue?: T) => {
  const [value, setValue] = useState<T>(initialValue);
  const [isPristine, setisPristine] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (validator) {
      setIsError(!validator(initialValue));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = useCallback(
    (newValue: T) => {
      const nextValue = newValue || defaultValue;
      if (validator) {
        setIsError(!validator(nextValue));
      }
      setisPristine(false);
      setValue(nextValue!);
    },
    [defaultValue, validator],
  );

  return {
    value: value || defaultValue,
    isPristine,
    onChange,
    isError,
  };
};

const EditFormTeacher = ({
  profile,
  onSave,
  switchToViewMode,
  disableButton,
}: {
  profile: ITeacher;
  onSave: (profile: ITeacher) => void;
  switchToViewMode?: () => void;
  disableButton?: boolean;
}) => {
  const [unsavedPersonalInterests, setUnsavedPersonalInterests] = useState<PersonalInterest[]>(
    profile.personalInterests || [],
  );

  const { permissions } = useTeacherPermissions();
  const languageOptions = useLanguageOptions();
  const teacherCenterName = useTeacherCenterById(profile.departmentId)?.name;

  const togglePersonalInterest = useCallback(
    (label: PersonalInterest) => {
      if (unsavedPersonalInterests.includes(label)) {
        setUnsavedPersonalInterests(unsavedPersonalInterests.filter((pi) => pi !== label));
      } else {
        setUnsavedPersonalInterests([...unsavedPersonalInterests, label]);
      }
    },
    [unsavedPersonalInterests],
  );

  const {
    value: selectedLangs,
    isError: isLanguagesError,
    isPristine: isLanguagesPristine,
    onChange: onLanguagesChange,
  } = useField<string[]>(profile.instructionLanguages);

  const onSaveInner = useCallback(() => {
    onSave({
      personalInterests: unsavedPersonalInterests,
      instructionLanguages: selectedLangs || [],
    } as ITeacher);
  }, [onSave, selectedLangs, unsavedPersonalInterests]);

  return (
    <TeacherEditWrapper editMode>
      <p style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 24 }}>Edit Profile</p>
      <RenderBasics profile={profile} center={teacherCenterName} />
      <Hr />
      {renderLabeledValue('Email', profile.email)}
      <>
        <Label>Personal Interests</Label>
        <TileContainer>
          {allPersonalInterests.map((piId) => (
            <Tile
              key={piId}
              isSelected={unsavedPersonalInterests.includes(piId)}
              disabled={!permissions.teacher.EditTeacherPersonalInterests()}
              onClick={() => togglePersonalInterest(piId)}
              data-name={piId}
            >
              <Icon icon={personalInterestsIcons[piId] as IconUnion} key={personalInterestsIcons[piId]} size={20} />
              <TileText>{allPersonalInterestsLabels[piId]}</TileText>
            </Tile>
          ))}
        </TileContainer>
      </>
      {renderList('Skills', profile.skills || [], (el) => allSkillsLabels[el as Skill])}
      {permissions.teacher.EditTeacherInstructionLanguages() ? (
        <FieldContainer showError={!isLanguagesPristine && isLanguagesError} style={{ width: 230 }}>
          <Label>Spoken Languages</Label>
          <MultiselectDropdown
            name="language"
            id="langs"
            options={languageOptions}
            size={16}
            disabled={!permissions.teacher.EditTeacherInstructionLanguages()}
            onChange={(_, newVals) => onLanguagesChange(newVals)}
            values={selectedLangs || []}
          />
        </FieldContainer>
      ) : (
        renderList('Spoken Languages', profile.instructionLanguages, getEnglishFullLangName)
      )}

      <ButtonWrapper>
        {switchToViewMode && (
          <SecondaryButton style={{ marginRight: 8 }} onClick={() => switchToViewMode()}>
            Cancel
          </SecondaryButton>
        )}
        <PrimaryButton disabled={disableButton} onClick={onSaveInner}>
          Save changes
        </PrimaryButton>
      </ButtonWrapper>
    </TeacherEditWrapper>
  );
};

const TeacherViewOfProfile = ({ profile, isOpen, onUpdateProfile, triggerTeacherCb }: InnerProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const switchToEditMode = useCallback(() => setEditMode(true), []);
  const switchToViewMode = useCallback(() => setEditMode(false), []);

  const onSave = useCallback(
    (newProfile: TeacherProfileToUpdate) => {
      setDisableButton(true);
      updateTeacherProfile(profile.id!, diffChangedProps(newProfile, profile as Record<keyof ITeacher, unknown>))
        .then(() => {
          onUpdateProfile(newProfile);
          switchToViewMode();
          toaster('Profile saved.', ToasterType.SUCCESS);
        })
        .catch((error) => {
          toaster('Saving profile has failed.', ToasterType.ERROR);
          ddlog.error('Saving profile has failed in SideBar TeacherViewOfProfile', { error });
        })
        .finally(() => {
          setDisableButton(false);
        });
    },
    [onUpdateProfile, profile, switchToViewMode],
  );

  useEffect(() => {
    if (!isOpen) {
      setEditMode(false);
    }
  }, [isOpen]);

  return !editMode ? (
    <TeacherViewWrapper>
      <CloseWrapper onClick={() => (triggerTeacherCb ? triggerTeacherCb() : null)}>
        <CloseIcon />
      </CloseWrapper>
      <ViewContent profile={profile} switchToEditMode={switchToEditMode} />
    </TeacherViewWrapper>
  ) : (
    <EditFormTeacher
      switchToViewMode={switchToViewMode}
      profile={profile}
      onSave={onSave}
      disableButton={disableButton}
    />
  );
};

const Profile = ({
  teacherId,
  renderAvatar,
  school,
  profile: propsProfile,
  onSave,
  isLarge,
  isOpen,
  setIsOpen,
  setSelectedTeacher,
  ...props
}: Props) => {
  const [profile, setProfile] = useState(propsProfile);
  const [activeSubNav, setActiveSubNav] = useState<string>('profile');

  useEffect(() => {
    if (isOpen) {
      fetchTeacherProfile(teacherId).then((teacherProfile) => {
        setProfile(teacherProfile);
      });
    }
  }, [isOpen, teacherId]);

  const onUpdateProfile = useCallback(
    (newProfile: ITeacher) => {
      setProfile({ ...profile, ...newProfile });
      onSave?.(newProfile);
    },
    [onSave, profile],
  );

  const triggerTeacherCb = useCallback(
    (evt?: MouseEvent) => {
      evt?.preventDefault();
      evt?.stopPropagation();
      // handles removal of scroll on the body and paddingRight makes it seamless
      // uses same interaction as when modals open
      document.body.style.overflow = isOpen ? '' : 'hidden';
      document.body.style.paddingRight = isOpen ? '' : '5px';
      setActiveSubNav('profile');
      setIsOpen(!isOpen);
    },
    [isOpen, setIsOpen],
  );

  return (
    <>
      <SubNavigation>
        <SubNavButton isActive={activeSubNav === 'profile'} onClick={() => setActiveSubNav('profile')}>
          <SubNavText>Profile</SubNavText>
        </SubNavButton>
      </SubNavigation>
      <School>{school}</School>
      {activeSubNav === 'profile' &&
        (!profile ? (
          <LoadingMessage />
        ) : (
          <ExpandedContainer>
            <TeacherViewOfProfile
              isOpen={isOpen}
              onUpdateProfile={onUpdateProfile}
              profile={profile}
              triggerTeacherCb={triggerTeacherCb}
              {...props}
            />
          </ExpandedContainer>
        ))}
    </>
  );
};

export default Profile;
